// ============================================================================
// DashBoardService
// -----------------------
// DashBoard module related services
// ============================================================================
// -------
// Imports
// -------
import { NotImplementedYet, RequiredArguments } from '@/helpers/methods'
import API from '@/apis/DashBoardApi'
// ---------
// Internals
// ---------
const Private = {
    requests: {
        dashBoard: {
            create: NotImplementedYet,
            read: function (holdingId, dashBoardName, params = null) {
                RequiredArguments({ holdingId: holdingId, dashBoardName: dashBoardName })
                return API.dashBoard.read(holdingId, dashBoardName, params)
            },
            update: NotImplementedYet,
            delete: NotImplementedYet
        },
        dashBoards: {
            create: NotImplementedYet,
            read: NotImplementedYet,
            update: NotImplementedYet,
            delete: NotImplementedYet
        }
    },
    service: {
        dashBoard: {
            create: NotImplementedYet,
            read: function (holdingId, dashBoardName, params = null) {
                RequiredArguments({ holdingId: holdingId, dashBoardName: dashBoardName })
                return Private.requests.dashBoard.read(holdingId, dashBoardName, params).then(data => data.url)
            },
            update: NotImplementedYet,
            delete: NotImplementedYet
        },
        dashBoards: {
            create: NotImplementedYet,
            read: NotImplementedYet,
            update: NotImplementedYet,
            delete: NotImplementedYet
        }
    },
    store: {}
}
// -------
// Exports
// -------
export default {
    createDashBoard: Private.service.dashBoard.create,
    getDashBoard: Private.service.dashBoard.read,
    updateDashBoard: Private.service.dashBoard.update,
    deleteDashBoard: Private.service.dashBoard.delete,

    createDashBoards: Private.service.dashBoards.create,
    getDashBoards: Private.service.dashBoards.read,
    updateDashBoards: Private.service.dashBoards.update,
    deleteDashBoards: Private.service.dashBoards.delete
}