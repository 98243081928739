import { mapState } from 'vuex'

export default {
	name: 'ContextMixin',
	props: {
		context: {
			type: [String, Object],
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			printed: false
		}
	},
	computed: {
		...mapState({
			currentUser: state => state.user,
			selectedAccountingFirm: state => state.accountingFirm.selected,
			selectedVendor: state => state.company.selected
		}),
		contextData: function () {
			let result
			if (this.context && typeof this.context == 'string') {
				const decryptedContext = this.$router.decryptContext(this.context)
				result = {
					accountingFirmId: decryptedContext.accounting_firm_id,
					vendorId: decryptedContext.vendor_id
				}
			} else if (this.context && this.context instanceof Object && (this.context.accountingFirmId || this.context.vendorId || this.context.userId)) {
				result = this.context
			} else {
				result = {
					accountingFirmId: this.selectedAccountingFirm?.id,
					userId: this.currentUser?.id,
					vendorId: this.selectedVendor?.id
				}
			}
			return result
		},
		accountingFirmId: function () {
			return this.contextData.accountingFirmId
		},
		vendorId: function () {
			return this.contextData.vendorId
		},
		userId: function () {
			return this.contextData.userId
		}
	}
}
