// ============================================================================
// HoldingApi
// -------------------
// Holding api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import URLS from '@/apis/BackEndAPIEndpoints'

import { RequiredArguments } from '@/helpers/methods'

// -------
// Exports
// -------
export default {
    vendor: {
        holding: {
            read: function (vendorId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
				RequiredArguments({ vendorId })
				return Backend.GET(URLS.api.vendors.holdings.uri, [vendorId], params, doCancelPreviousRequest, doCancelAllOtherRequests)
			}
        }
    },
    holding: {
        modules: {
            read: function (holdingId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({holdingId})
                return Backend.GET(URLS.api.holdings.modules.uri, [holdingId], params , doCancelPreviousRequest, doCancelAllOtherRequests)
            }
        },
        users: {
            read: function (holdingId, params = { 'page': 1 }, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({holdingId})
                return Backend.GET(URLS.api.holdings.users.uri, [holdingId], params , doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            create: function (holdingId, data, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                return Backend.POST(URLS.api.holdings.users.uri, [holdingId], null, data, doCancelPreviousRequest, doCancelAllOtherRequests)
            }
        },
        user: {
            vendors: {
                read: function (holdingId, userId, params = null, data = null,doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                    return Backend.GET(URLS.api.holdings.users.vendors.uri, [holdingId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
                }
            },
            invite: function (holdingId, userId, params = null, data = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({holdingId, userId})
                return Backend.POST(URLS.api.holdings.users.sendMail.uri, [holdingId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            update: function (holdingId, userId, data, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({holdingId, userId, data})
                return Backend.PATCH(URLS.api.holdings.users.uri, [holdingId, userId], params, data, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
            delete: function (holdingId, userId, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
                RequiredArguments({holdingId, userId})
                return Backend.DELETE(URLS.api.holdings.users.uri, [holdingId, userId], params, null, doCancelPreviousRequest, doCancelAllOtherRequests)
            },
        }
    }
}