<template>
	<w-layout column fill-height>
		<w-flex v-if="menu.length > 1" shrink>
			<w-tabs v-model="activeTab" centered icons-and-text next-icon="mdi-arrow-right-bold-box-outline" prev-icon="mdi-arrow-left-bold-box-outline" show-arrows>
				<v-tabs-slider color="primary"></v-tabs-slider>
				<v-tab v-for="section in menu" :key="section.key" @click="selectSection(section.name)">
					{{ section.title }}
					<v-icon>{{ section.icon }}</v-icon>
				</v-tab>
			</w-tabs>
		</w-flex>
		<w-flex shrink fill-height>
			<v-tabs-items v-model="activeTab">
				<v-tab-item v-for="item in menu" :key="item.key">
					<DashBoard :dash-board="item.name"></DashBoard>
				</v-tab-item>
			</v-tabs-items>
		</w-flex>
	</w-layout>
</template>	

<script>
import DashBoardModuleGuard from '@/mixins/ModulesGuards/DashBoard/DashBoardModuleGuard'
export default {
	name: 'DashBoardManager',
	components: {
		DashBoard: () => ({
			component: import('@/components/DashBoard/DashBoard')
		})	
	},
	mixins: [DashBoardModuleGuard],
	props: {
	},
	data: function () {
		return {
			activeTab: 0,
			currentDashBoard: 'dashboard',
			componentSelected: 'GeneralDashBoard',
			menu: [],
			generalDashboard : { icon: 'dashboard', key: 0, route: '', title: this.$t('holding.dashboard.general_dashboard'), component: 'GeneralDashBoard', name: 'general-dashboard'},
			deployDashboard: { icon: 'mediation', key: 1, route: '', title: this.$t('holding.dashboard.deploy_dashboard'), component: 'DeploymentDashBoard', name: 'deploy-dashboard'},
			uploadDashBoard: { icon: 'file_upload', key: 2, route: '', title: this.$t('holding.dashboard.upload_dashboard'), component: 'UploadDashBoard', name: 'upload-dashboard'},
		}
	},
	computed: {
	},
	watch: {
	},
	created: function () {
	},
	mounted: function () {
		this.initDashBoards()
		// Ugly hack to show the arrow on the tab slider
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'))
		}, 400)
	},
	destroyed: function () {
	},
	methods: {
	selectSection: function (dashboard) {
			this.currentDashBoard = dashboard
		},
	initDashBoards () {
		if (this.holdingService.hasHoldingModule('general-dashboard')) {
			this.generalDashboard.key = this.menu.length
			this.menu.push(this.generalDashboard)
		}
		if (this.holdingService.hasHoldingModule('deploy-dashboard')) {
			this.deployDashboard.key = this.menu.length
			this.menu.push(this.deployDashboard)
		}
		if (this.holdingService.hasHoldingModule('upload-dashboard')) {
			this.uploadDashBoard.key = this.menu.length
			this.menu.push(this.uploadDashBoard)
		}
	}
	},
	
}
</script>
<style scope>
	.v-window {
		height: 100%;
	}
	.v-window__container {
		height: 100%;
	}
	.v-window-item {
		height: 100%;
	}
</style>