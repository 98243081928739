import AbstractContextualizedModuleGuard from '@/mixins/ModulesGuards/AbstractContextualizedModuleGuard'
import Service from '@/services/DashBoard/DashBoardService'
import { Events, Bus } from '@/events/DashBoard/DashBoardEvents'
import HoldingService from '@/services/Holding/HoldingService'

export default {
	mixins: [AbstractContextualizedModuleGuard],
	data: function () {
		return {
			moduleName: 'dashboard',
			eventBus: Bus,
			service: Service,
			holdingService: HoldingService,
			events: Events
		}
	},
	computed: {
		hasModule: function () {
			if (!this.moduleName) {
				throw new Error("'moduleName' not defined")
			}
			const modules = this.$store.getters['modules/getHoldingModules']
            return modules.find(module => module.name === this.moduleName) != undefined
		}
	},
	mounted: function () {
		if (!this.hasModule) {
			this.$el.remove()
		}
	}
}
