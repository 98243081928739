// ============================================================================
// DashBoardApi
// -------------------
// DashBoard api
// ============================================================================

// -------
// Imports
// -------
import { Backend } from '@/plugins/axios/Configurator'
import { RequiredArguments, NotImplementedYet } from '@/helpers/methods'
import URLS from '@/apis/BackEndAPIEndpoints'

// -------
// Exports
// -------
export default {
	dashBoard: {
        create: NotImplementedYet,
        read: function (holdingId, dashBoardName, params = null, doCancelPreviousRequest = false, doCancelAllOtherRequests = false) {
            RequiredArguments({ holdingId: holdingId, dashBoardName: dashBoardName })
            return Backend.fetchApi.GET(
                URLS.api.dashboards.uri,
                [holdingId, dashBoardName],
                params,
                doCancelPreviousRequest,
                doCancelAllOtherRequests,
                Backend.fetchApi.priority.low
            )
        },
        update: NotImplementedYet,
        delete: NotImplementedYet
    },
    dashBoards: {
        create: NotImplementedYet,
        read: NotImplementedYet,
        update: NotImplementedYet,
        delete: NotImplementedYet
    }
}