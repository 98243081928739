// ============================================================================
// HoldingService
// -----------------------
// Holding module related services
// ============================================================================
// -------
// Imports
// -------
import { RequiredArguments } from '@/helpers/methods'
import API from '@/apis/HoldingApi'
import { store } from '@/store'
// ---------
// Internals
// ---------
const Private = {
    requests: {
        vendor: {
            holding: {
                read: function (vendorId) {
					RequiredArguments({ vendorId: vendorId })
					return API.vendor.holding.read(vendorId)
				}
            }
        },
        holding: {
            modules: {
                read: function (holdingId) {
                    RequiredArguments({holdingId})
                    return API.holding.modules.read(holdingId)
                }
            },
        },
    },
    service: {
        vendor: {
            holding: {
                read: function (vendorId) {
					RequiredArguments({ vendorId: vendorId })
					return Private.requests.vendor.holding.read(vendorId)
				}
            }
        },
        holding: {
            modules: {
                read: function (holdingId) {
                    RequiredArguments({holdingId})
                    return Private.requests.holding.modules.read(holdingId).then(modules => {
                        return Private.store.holding.modules.set(modules).then(() => {
                            return Private.store.holding.modules.get()
                        })
                    })
                }
            },
        }
    },
    store: {
        holding: {
            modules: {
                set: function (modules) {
                    RequiredArguments({ modules: modules })
                    return store.dispatch('modules/setHolding', modules)
                },
                get: function () {
                    return store.getters['modules/getHoldingModules']
                },
                hasModule: function (moduleName) {
                    RequiredArguments({ moduleName: moduleName })
                    const modules = Private.store.holding.modules.get()
                    return modules.find(module => module.name === moduleName) != undefined
                }
            },
        }
    }
}
// -------
// Exports
// -------
export default {
    findHolding: Private.service.vendor.holding.read,
    getHoldingModules: Private.service.holding.modules.read,
    hasHoldingModule: Private.store.holding.modules.hasModule
}
